<template>
  <div class="dialogDiyNew" v-if="isShows" ref="dia" @click.self="bgEvent">
    <div class="operaErea" :style="sizeStyle">
      <div class="closeBtn" @click="closeEvent">
        <img src="../../assets/img/close.png" alt=""/>
      </div>
      <div v-if="dialogDiyData.title" class="dialog_header">{{dialogDiyData.title}}</div>
      <el-scrollbar :class="[dialogDiyData.title ? 'dialogScroll' : 'allScroll']">
        <slot name="dialogContent"></slot>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dialogDiyNew',
  props: ['isShow', 'dialogDiyData'],
  data() {
    return {
      isShows: false,
      sizeStyle: '',
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isShows = newVal;
        if(newVal) {
          this.initDia();
        }
      }
    },
  },
  methods: {
    initDia() {
      this.$nextTick(()=>{
        this.sizeStyle = this.dialogDiyData.sizeStyle;
      })
    },
    bgEvent() {
      // this.closeEvent();
    },
    closeEvent() {
      let obj = {
        isShow: false
      }
      this.$emit('dialogEvent', obj)
    }
  },
}
</script>

<style lang='less'>
  .dialogDiyNew {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.397);
    overflow: auto;
    overflow-x: hidden;
    .operaErea {
      position: relative;
      margin: 0 auto;
      margin-top: 10vh;
      background-color: #fff;
      border-radius: 20px;
      .closeBtn {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 10;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .dialog_header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        color: #454545;
        border-bottom: 1px solid #F4F7FC;
      }
    }
  }
.dialogScroll {
  height: calc(100% - 71px);
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar__view {
    overflow-x: hidden;
    & > div {
      height: 100%;
    }
  }
}
.allScroll {
  height: 100%;
  .el-scrollbar__wrap {
    height: 100%;
    overflow-x: hidden;
  }
  .el-scrollbar__view {
    height: 100%;
    overflow-x: hidden;
    padding: 0;
  }
}
</style>