<template>
  <div class="studentManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='studentManagement'">
        <div class="search_sList">
          <!-- <el-tabs v-model="activeName">
            <el-tab-pane label="学生管理" name="first"></el-tab-pane>
          </el-tabs> -->
          <!-- <div class="pc_header">
            <span class="son_title">学生管理</span>
            <div class="right_pc_area">
              <span class="go_back" @click="goBack">返回上一级</span>
              <span class="bread_area">学校列表/学生管理</span>
            </div>
          </div> -->
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
              <el-form-item label="选择学校">
                <el-select v-model="formInline.tenantId" placeholder="选择学校" @change="changeEvent">
                  <el-option
                    v-for="item in allSchoolData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="教学点名">
                <el-select v-model="formInline.stationId" placeholder="教学点名">
                  <el-option
                    v-for="item in correspondenceStation"
                    :key="item.stationsId"
                    :label="item.stationsName"
                    :value="item.stationsId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入学批次">
                <el-select v-model="formInline.admissionBatch" filterable placeholder="入学批次">
                  <el-option
                    v-for="item in schoolBatchData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="层次">
                <el-select v-model="formInline.eduCategory" placeholder="层次">
                  <el-option v-for="(item, key, index) in $common.getEduCategory" :key="index" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="专业">
                <el-select v-model="formInline.majorName" placeholder="专业" @focus="getSearchMajor" filterable clearable>
                  <el-option
                    v-for="item in majorNameData"
                    :key="item.majorName"
                    :label="item.majorName"
                    :value="item.majorName">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学籍状态">
                <el-select v-model="formInline.studentStatus" @focus="getDictItemByType" placeholder="学籍状态" filterable clearable>
                  <el-option
                    v-for="item in studentStatusItemData"
                    :key="item.label"
                    :label="item.label"
                    :value="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学生姓名">
                <el-input v-model="formInline.name" placeholder="学生姓名"></el-input>
              </el-form-item>
              <el-form-item label="学号">
                <el-input v-model="formInline.studentNum" placeholder="学号"></el-input>
              </el-form-item>
              <!-- <el-form-item>
                <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
                <el-button type="info" plain @click="ResetEvent">重置</el-button>
              </el-form-item> -->
              <el-form-item label="学习权限">
                <el-select v-model="formInline.learningPermissions" placeholder="学习权限">
                  <el-option v-for="(item, key, index) in $common.learningPermissions" :key="index" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="ResetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="addStuEvent" plain v-allow="'sys_student_add'">+ 添加新学生</el-button>
            <el-button class="mainBtn" @click="uploadFile" plain v-allow="'sys_student_import'">批量导入</el-button>
            <el-button class="mainBtn" @click="Datchexport" plain v-allow="'sys_student_export'">批量导出</el-button>
            <!-- <el-button class="mainBtn" @click="exportEvent" plain v-allow="'sys_student_export'">批量导出</el-button> -->
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            row-key='id'
            ref="multipleTable"
            @selection-change="handleSelectionChange">
            <!-- <el-table-column
              label="序号"
              align="center"
              width="80">
              <template slot-scope="scope">
                  {{ (formInline.currentPage - 1) * formInline.pageSize + scope.$index + 1 }}
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55">
            </el-table-column>
            <el-table-column
              prop="admissionBatch"
              label="入学批次"
              align="center"
              width="80">
            </el-table-column>
            <el-table-column
              prop="studentNum"
              label="学生学号"
              align="center"
              width="120">
            </el-table-column>
            <el-table-column
              prop="eduCategory"
              label="层次"
              align="center"
              :formatter="regEduCategory">
            </el-table-column>
            <el-table-column
              prop="majorName"
              label="专业"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.majorName" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.majorName || '/'}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="stationName"
              label="教学点名称"
              align="center">
            </el-table-column>
            <el-table-column
              prop="name"
              label="学生姓名"
              align="center">
            </el-table-column>
            <el-table-column
              prop="examNum"
              label="考生号"
              align="center">
            </el-table-column>
            <el-table-column
              prop="loginNum"
              label="账号"
              align="center">
            </el-table-column>
            <el-table-column
              prop="studentStatus"
              label="学籍状态"
              align="center">
            </el-table-column>
            <el-table-column
              prop="paymentStatus"
              label="缴费状态"
              align="center">
              <template slot-scope="scope">
                <el-tag v-if="String(scope.row.paymentStatus) == '0'">已缴费</el-tag>
                <el-tag type="warning" v-else-if="String(scope.row.paymentStatus) == '1'">待缴费</el-tag>
                <el-tag type="warning" v-else-if="String(scope.row.paymentStatus) == '2'">免费试用</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="learningPermissions"
              label="学习权限"
              align="center">
              <template slot-scope="scope">
                <el-tag v-if="String(scope.row.learningPermissions) == '0'">已开通</el-tag>
                <el-tag type="warning" v-else-if="String(scope.row.learningPermissions) == '1'">待开通</el-tag>
                <el-tag type="warning" v-else-if="String(scope.row.learningPermissions) == '2'">审核中</el-tag>
                <el-tag type="info" v-else-if="String(scope.row.learningPermissions) == '3'">已关闭</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="100"
              align="center">
              <template slot-scope="scope">
                <el-dropdown>
                  <span class="el-dropdown-link">
                     <el-button type="text">操作选择</el-button><i class="el-icon-arrow-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="pubDropMenu">
                    <!-- <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="compileEvent(scope.row)">编辑</el-button>
                    </el-dropdown-item> -->
                    <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="editEvent(scope.row)" v-allow="'sys_student_edit'">修改</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="watchState(scope.row)">查看详情</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="String(scope.row.learningPermissions) == '0' && $isShowModle('sys_student_close')">
                      <el-button class="pub_style" type="text" @click="closeUser(scope.row)">关闭账号</el-button>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="deleteEvent(scope.row)" v-allow="'sys_student_del'">删除</el-button>
                    </el-dropdown-item> -->
                    <el-dropdown-item>
                      <el-button class="pub_style" type="text" @click="resetEvent(scope.row)">重置密码</el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
      </template>
      <!-- <template v-else>
        <router-view></router-view>
      </template> -->
    </el-scrollbar>
    <dialogDiy :isShow="isResShow" :dialogDiyData="dialogResDiyData"
    @dialogEvent="dialogResDiyEvent">
      <div slot="dialogContent" class="result_area">
        <img src="../../assets/img/enter.png" alt=""/>
        <span>您的请求已提交</span>
        <span>稍后请在 <span @click="jumpTask" class="myWork">我的任务</span> 中查看处理结果！</span>
      </div>
    </dialogDiy>
    <el-dialog class="compile" title="编辑" :visible.sync="editVisible">
      <el-form :model="form">
        <el-form-item label="学籍状态" label-width="100">
          <el-select v-model="form.studentStatus" placeholder="请选择学籍状态">
            <!-- <el-option
              v-for="item in studentOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="缴费状态" label-width="100">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <!-- <el-option label="已缴费" value="shanghai"></el-option>
            <el-option label="代缴费" value="beijing"></el-option>
            <el-option label="试用期" value="beijing"></el-option> -->
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="editVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent">
        <div class="dia_opera_stu">
          <div class="opera_left">
            <img src="../../assets/img/file.png"/>
            <span>学生批量导入样例</span>
            <el-button class="mainBtn" @click="downLoadMolde">点击下载</el-button>
          </div>
          <div class="opera_right">
            <div class="bor" @click="getLocalPic" v-if="!excelUrl">
              <img src="../../assets/img/adds.png"/>
              <span>学生批量导入样例</span>
            </div>
            <div class="bor noBorder" @click="getLocalPic" v-else>{{showFileName}}</div>
            <el-button class="mainBtn" @click="enterUpload">确认上传</el-button>
          </div>
        </div>
      </div>
    </dialogDiy>

    <!-- 批量导出课程安排 -->
    <dialogDiy :isShow="isShowOut" :dialogDiyData="dialogOutDiyData"
    @dialogEvent="dialogOutEvent">
      <div slot="dialogContent" class="opera_area">
        <el-form :inline="true" :model="formExport" class="demo-form-inline" label-width="100px">
          <!-- <el-form-item label="选择学校">
            <el-select v-model="formExport.region" placeholder="选择学校">
              <el-option
                v-for="item in allSchoolData"
                :key="item.tenantId"
                :label="item.tenantName"
                :value="item.tenantId">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="入学批次">
            <el-select v-model="formExport.admissionBatch" placeholder="入学批次" filterable clearable>
              <el-option
                v-for="item in schoolBatchData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教学层次">
            <el-select v-model="formExport.eduCategory" placeholder="教学层次" clearable>
              <el-option label="专升本" value="TOP_UP"></el-option>
              <el-option label="高起本" value="HIGH_UP_THE"></el-option>
              <el-option label="高起专" value="HIGH_UP_SPECIALLY"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业">
            <el-select v-model="formExport.majorName" placeholder="专业" filterable clearable @focus="getNewMajorDataEvent">
              <el-option
                v-for="item in searchMajorNameData"
                :key="item.majorName"
                :label="item.majorName"
                :value="item.majorName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button class="mainBtn" @click="exportEvents" plain>导出</el-button>
        </div>
      </div>
    </dialogDiy>

    <!-- 导出 -->
    <fileExportNew
      :isShowFile="stuAreaObj.isShowFile"
      :title="stuAreaObj.title"
      @closeFile="exportEvent"
      @enter="enterExportEvent"
    ></fileExportNew>

    <input style="display: none;" type="file" @change="picChange()" ref="loadPic" accept=" application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
  </div>
</template>

<script>
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
import fileExportNew from 'components/commonModule/fileExportNew'
import way from '../../api/encapsulation'
import { excelFileUpload } from '../../api/fileUpload'
const OSS = require('ali-oss');
export default {
  name: 'studentManagement',
  components: {
    Pagination,
    dialogDiy,
    fileExportNew,
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        admissionBatch: null,
        currentPage: 1,
        eduCategory: null,
        studentNum: null,
        majorName: null,
        name: null,
        pageSize: 10,
        stationId: null,
        studentStatus: null,
        tenantId: null,
        learningPermissions: null
      },
      studentStatusItemData: [],
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      isShow: false,
      dialogDiyData: {
        title: '学生批量导入',
        sizeStyle: {width: '640px',height: '350px'}
      },
      isShowOut: false,
      dialogOutDiyData: {
        title: '批量导出学生管理',
        sizeStyle: {width: '400px',height: '350px'}
      },
      editVisible: false,
      form: {},
      isResShow: false,
      dialogResDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '204px'}
      },
      allSchoolData: [],
      correspondenceStation: [],
      schoolBatchData: [],
      majorNameData: [],
      searchMajorNameData: [],
      formExport: {
        admissionBatch: null,
        eduCategory: null,
        majorName: null,
        tenantId: null,
      },
      formImport: {
        fileName: null,
        importFileUrl: null,
        tenantId: null
      },
      excelUrl: '',
      fileName: '',
      showFileName: '',
      stuAreaObj: {
        isShowFile: false,
        title: '请选择导出学生范围'
      },
      idArr: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'studentManagement') {
          this.formInline.tenantId = Number(newVal.query.tenantId);
          this.formExport.tenantId = Number(newVal.query.tenantId);
          this.getStudentManData();
        }
      }
    }
  },
  created() {
    this.getTenantDown();
    this.getGoSchoolData();
  },
  methods: {
    handleSelectionChange(val) {
      this.idArr = val.map(item => item.id)
    },
    // 获取学籍状态数据
    getDictItemByType() {
      this.$request
        .getDictItemByType({ type: "student_status" })
        .then((res) => {
          if (res.data.code == 0) {
            this.studentStatusItemData = res.data.data;
          }
        });
    },
    // 专业数据
    getMajorDataEvent(val) {
      if(val != 'refresh') if(this.majorNameData.length != 0) return;
      let tenantId = this.formExport.tenantId;
      this.$request.getMajorData({tenantId}, res=>{
        if(res.code == 0) {
          this.majorNameData = res.data;
        }
      })
    },
    // 专业数据-层次，批次-搜索区
    getSearchMajor() {
      if(!this.formInline.admissionBatch) return;
      if(!(typeof this.formInline.eduCategory == 'string' || typeof this.formInline.eduCategory == 'number')) return;
      let obj = {
        tenantId: this.formInline.tenantId,
        eduCategory: this.formInline.eduCategory,
        batch: this.formInline.admissionBatch
      }
      this.$request.getMajorList(obj).then(res=>{
        if(res.data.code == 0) {
          this.majorNameData = res.data.data;
        }
      })
    },
    // 专业数据-层次，批次
    getNewMajorDataEvent() {
      if(!this.formExport.admissionBatch) return;
      if(!this.formExport.eduCategory) return;
      let eduCategory;
      if(this.formExport.eduCategory == 'TOP_UP') {
        eduCategory = 0;
      } else if(this.formExport.eduCategory == 'HIGH_UP_THE') {
        eduCategory = 1;
      } else {
        eduCategory = 2;
      }
      let obj = {
        tenantId: this.formExport.tenantId,
        eduCategory,
        batch: this.formExport.admissionBatch
      }
      this.$request.getMajorList(obj).then(res=>{
        if(res.data.code == 0) {
          this.searchMajorNameData = res.data.data;
        }
      })
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if(res.code == 0) {
          this.allSchoolData = res.data;
          this.getTheDropDownBoxData();
        }
      })
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.formInline.tenantId
      }
      this.$request.getTheDropDownBoxs(obj, res => {
        if(res.code == 0){
          this.correspondenceStation = res.data;
        }
      })
    },
    changeEvent() {
      this.formInline.stationId = '';
      this.formInline.admissionBatch = '';
      this.getTheDropDownBoxData();
      this.getGoSchoolData();
      // this.getMajorDataEvent('refresh');
    },
    // 获取入学批次数据
    getGoSchoolData() {
      let obj = {
        tenantId: this.formInline.tenantId
      }
      this.$request.getGoSchool(obj, res => {
        if(res.code == 0) {
          this.schoolBatchData = res.data;
        }
      })
    },
    // 获取学生管理表格数据
    getStudentManData() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getStudentData(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    // 重置
    ResetEvent() {
      this.formInline.admissionBatch = null;
      this.formInline.eduCategory = null;
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.formInline.majorName = null;
      this.formInline.studentStatus = null;
      this.formInline.stationId = null;
      this.formInline.name = null;
      this.formInline.studentNum = null;
      this.formInline.learningPermissions = null;
      this.formInline.tenantId = Number(this.$route.query.tenantId);
      this.majorNameData = [];
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.currentPage = val.current;
      this.formInline.pageSize = val.size;
      this.getStudentManData();
    },
    Datchexport() {
      this.isShowOut = !this.isShowOut;
    },
    dialogOutEvent(val) {
      this.isShowOut = val.isShow;
    },
    // 导出
    exportEvents() {
      let obj = JSON.parse(JSON.stringify(this.formExport));
      for (let key in obj) {
        obj[key] = obj[key] || null
      }
      this.$request.exportStudent(obj).then( res => {
        if(res.data.code == 0){
          this.Success(res.data.msg);
          this.isResShow = true;
          this.isShowOut = false;
        }
      })
    },
    regEduCategory(row) {
      let edu = String(row.eduCategory)
      let val = this.$common.getEduCategory[edu];
      return val;
    },
    regLearning(row) {
      // if(row.learningPermissions) {
        let edu = String(row.learningPermissions)
        let val = '';
        switch(edu) {
          case '0':
            val = '已开通';
            break;
          case '1':
            val = '待开通';
            break;
          case '2':
            val = '审核中';
            break;
        }
        return val;
      // }
    },
    regStudentStatus(row) {
      // if(row.studentStatus) {
        let edu = String(row.studentStatus)
        let val = '';
        switch(edu) {
          case '0':
            val = '在读';
            break;
          case '1':
            val = '已毕业';
            break;
          case '2':
            val = '待录取';
            break;
        }
        return val;
      // }
    },
    regPaymentStatus(row) {
      // if(row.paymentStatus) {
        let edu = String(row.paymentStatus)
        let val = '';
        switch(edu) {
          case '0':
            val = '已缴费';
            break;
          case '1':
            val = '待缴费';
            break;
          case '2':
            val = '免费试用';
            break;
        }
        return val;
      // }
    },
    // 添加学生
    addStuEvent() {
      this.$router.push({name: 'addStudent', query: {
        status: 'add',
        tenantId: this.$route.query.tenantId,
        encode: this.$route.query.encode
      }});
    },
    // 编辑
    compileEvent(row) {
      this.editVisible = true;
    },
    // 修改
    editEvent(row) {
      this.$router.push({name:'addStudent',query: {
        headTitle: '学生信息修改',
        status: 'edit',
        id: row.id,
        tenantId: this.$route.query.tenantId
      }})
    },
    // 查看详情
    watchState(row) {
      this.$router.push({name:'addStudent',query: {
        headTitle: '学生信息查看',
        status: 'watchs',
        id: row.id,
        tenantId: this.$route.query.tenantId
      }})
    },
    closeUser(row) {
      this.$confirm('是否关闭该学生账号', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$request.closeStudent({studentId: row.id}).then(res => {
          // console.log(res)
          if(res.data.code == 0) {
            this.Success(res.data.msg);
            this.getStudentManData();
          }
        })
      }).catch(() => {});
    },
    // 删除
    deleteEvent(row) {
      this.$confirm('是否删除该学生', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$request.deleteByIds({id: row.id}, res => {
          // console.log(res)
          if(res.code == 0) {
            this.Success(res.msg);
            this.formInline.currentPage = 1;
            this.getStudentManData();
          } else {
            this.errEvent();
          }
        })
      }).catch(() => {});
    },
    errEvent() {
      this.$confirm('对不起，您的权限不足。')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    // 重置密码
    resetEvent(row) {
      this.$confirm('是否重置密码', '提示', {
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$request.resetpassword({loginNum: row.loginNum}).then(res => {
          // console.log(res)
          if(res.data.code == 0) {
            this.rest();
          }
        })
      }).catch(() => {});
    },
    rest() {
      this.$confirm('密码已重置')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    enterUpload() {
      if(!this.excelUrl) {
        this.Warn('请选择excel文件')
        return;
      }
      let obj = {
        "fileName": this.fileName,
        "importFileUrl": this.excelUrl,
        "tenantId": this.formInline.tenantId
      }
      this.$request.importStudent(obj).then(res=>{
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.excelUrl = '';
          this.fileName = '';
          this.showFileName = '';
          this.isShow = false;
          this.isResShow = true;
        }
      })
      this.isShow = false;
    },
    // 搜索
    onSubmit() {
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.getStudentManData();
    },
    // 确认上传
    getLocalPic() {
      this.$refs.loadPic.value= '';
      this.$refs.loadPic.click();
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if(obj) {
        this.fileName = obj.fileName;
        this.showFileName = obj.showFileName;
        this.excelUrl = obj.excelUrl;
      }
      // this.excelUrl = ossUrl;
    },
    uploadFile() {
      this.isShow = true;
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
      this.clearEvent();
    },
    // 下载模板
    downLoadMolde() {
      this.$request.getStudentTem(res=>{
        if(res.code == 0) {
          window.location.href = res.data;
        }
      })
    },
    dialogResDiyEvent(val) {
      this.isResShow = val.isShow;
    },
    clearEvent() {
      this.excelUrl = '';
      this.fileName = '';
    },
    jumpTask() {
      this.$store.commit('notActiveEvent', true);
      sessionStorage.removeItem('key')
      this.$router.push('/Home/taskCenter')
    },
    // goBack() {
    //   this.$router.go(-1);
    // }
    clearExportEvent() {
      this.formExport.admissionBatch = null;
      this.formExport.eduCategory = null;
      this.formExport.majorName = null;
      this.searchMajorNameData = [];
    },
    exportEvent() {
      this.stuAreaObj.isShowFile = !this.stuAreaObj.isShowFile
    },
    enterExportEvent() {
      if (val == 1 && this.idArr.length == 0) {
        this.Warn('请选择要导出的数据')
        return;
      }
    },
  },
}
</script>

<style lang="less">
  .studentManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .pc_header {
        height: 50px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F4F7FC;
        background-color: #fff;
        margin-bottom: 28px;
        span {
          display: inline-block;
          height: 100%;
          line-height: 50px;
          font-size: 12px;
          color: #1282FF;
          font-weight: 400;
        }
        .son_title {
          box-sizing: border-box;
          font-size: 14px;
          font-weight: 600;
          border-bottom: 3px solid #1282FF;
        }
        .go_back {
          cursor: pointer;
        }
        .bread_area {
          color: #777;
          margin-left: 42px;
        }
      }
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .result_area {
      // width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      span {
        color: #777777;
        font-size: 18px;
      }
      span:nth-of-type(1) {
        margin: 18px 0px;
      }
      .myWork {
        cursor: pointer;
        font-weight: 600;
        color: #1282FF;
      }
    }
    .compile {
      .el-dialog {
        width: 400px;
      }
    }
    .opera_area {
      height: calc(~'100% - 70px');
      box-sizing: border-box;
      padding: 0px 20px;
      position: relative;
      .demo-form-inline {
        padding-top: 20px;
      }
      .search_erea {
        text-align: center;
        .el-input {
          width: 300px;
          height: 50px;
          margin: 20px 0px;
          border-radius: 1px solid #E1E1E1;
        }
      }
      .btn_area {
        text-align: right;
        padding: 20px 0px;
        position: absolute;
        bottom: 0;
        right: 20px;
      }
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pubToop {
    max-width: 600px;
  }
</style>

<style lang="less" scoped>
  .dia_opera_stu {
    display: flex;
    box-sizing: border-box;
    padding: 30px;
    .opera_left, .opera_right {
      height: 180px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .opera_left {
      width: 200px;
      > img {
        width: 51px;
        height: 51px;
      }
      > span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    .opera_right {
      width: 188px;
      .bor {
        width: 188px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 2px dashed #E1E1E1;
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .noBorder {
        word-wrap:break-word;
        border: 0 none;
      }
    }
  }
</style>
