<template>
  <div class="fileExportNew">
    <dialogDiyNew
      :isShow="isShowFile"
      :dialogDiyData="dialogResDiyData"
      @dialogEvent="dialogResDiyEvent"
    >
      <div slot="dialogContent" class="select_areas">
        <div class="export_file_header">
          <slot name="exporthead"></slot>
        </div>
        <div class="export_cont_area">
          <el-radio-group v-model="type">
            <el-radio v-if="isShowAll" :label="0">{{txtObj.length > 0 ? txtObj[0] : '导出全部筛选信息'}}</el-radio>
            <el-radio v-if="isShowFilter" :label="1">{{txtObj.length > 0 ? txtObj[1] : '导出选择信息'}}</el-radio>
          </el-radio-group>
        </div>
        <div class="export_file_tips">
          <slot name="exporttips"></slot>
        </div>
        <div class="export_btn_area pub_btn">
          <template v-if="isDefaultBtn">
            <el-button type="primary" @click="enterEvent">确 定</el-button>
            <el-button @click="dialogResDiyEvent">取 消</el-button>
          </template>
          <template v-else>
            <slot name="customizeBtn" :type="type"></slot>
          </template>
        </div>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import dialogDiyNew from "./dialogDiyNew"
export default {
  name: 'fileExportNew',
  components: {
    dialogDiyNew,
  },
  props: {
    isShowFile: {
      type: Boolean,
      default: false
    },
    isShowAllFlag: {
      type: Boolean,
      default: false
    },
    isShowFilterFlag: {
      type: Boolean,
      default: false
    },
    txtObj: {
      type: Array,
      default: function () {
        return []
      }
    },
    title: {
      type: String,
      default: ""
    },
    isDefaultBtn: {
      type: Boolean,
      default: true
    },
    dialogData: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      type: 0,
      dialogResDiyData: {
        title: "请选择导出文件版本",
        sizeStyle: { width: "400px", height: "214px" }
      },
      isShowAll: true,
      isShowFilter: true,
    }
  },
  watch: {
    isShowFile: {
      deep: true,
      handler(newVal, oldVal) {
        // if (!newVal) return;
        if(this.title) this.dialogResDiyData.title = this.title
        if(this.isShowAllFlag) this.isShowAll = this.isShowAllFlag
        if (this.isShowFilterFlag) this.isShowFilter = this.isShowFilterFlag
        if (Object.keys(this.dialogData).length != 0) {
          this.dialogResDiyData.sizeStyle.height = this.dialogData.height
          this.dialogResDiyData.sizeStyle.width = this.dialogData.width
        } else {
          this.$set(this.dialogResDiyData, 'sizeStyle', { width: "400px", height: "214px" })
        }
      }
    }
  },
  methods: {
    dialogResDiyEvent() {
      this.$emit("closeFile")
    },
    enterEvent() {
      this.$emit('enter', this.type)
    }
  },
}
</script>

<style lang='less' scoped>
  .fileExportNew {
    /deep/ .el-scrollbar__view {
      padding: 0px!important;
    }
    .select_areas {
      // height: 136px!important;
      // padding: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .export_cont_area {
        margin-top: 20px;
      }
      .export_btn_area {
        margin-top: 30px;
        width: 100%;
        text-align: center;
        box-sizing: border-box;
      }
    }
    .export_file_tips {
      /deep/ .e_f_tips {
        padding: 20px 66px 0;
        font-size: 14px;
      }
    }
  }
</style>