import request from "./request";
import { Message, Loading } from "element-ui";
import way from './encapsulation'

// 任意类型文件上传
export const upload = (files) => {
  if (way.checkUrl(files.name)) {
    Message({
      message: "文件名内不能带有+、%",
      type: "warning",
    });
    return;
  }
  return new Promise((resolve, reject) => {
    //获取最后一个.的位置
    let index = files.name.lastIndexOf(".");
    //获取后缀
    let ext = files.name.substr(index + 1);
    request.uploadPic(files, (res) => {
      if (res.code == 0) {
        let fileName = files.name;
        let showFileName = files.name;
        // 获取文件名，不包括文件后缀名
        let fName = showFileName.substr(0, index);
        if (fName.length > 30) {
          showFileName = fName.substring(0, 31) + "···." + ext;
        }
        let excelUrl = res.data;
        resolve({
          excelUrl,
          fileName,
          showFileName,
        });
      } else {
        resolve(false);
      }
    });
  });
};

// excel文件上传封装
export const excelFileUpload = (files) => {
  if (way.checkUrl(files.name)) {
    Message({
      message: "文件名内不能带有+、%",
      type: "warning",
    });
    return;
  }
  return new Promise((resolve, reject) => {
    //获取最后一个.的位置
    let index = files.name.lastIndexOf(".");
    //获取后缀
    let ext = files.name.substr(index + 1);
    if (ext == "xlsx" || ext == "xls") {
      request.uploadPic(files, (res) => {
        if (res.code == 0) {
          let fileName = files.name;
          let showFileName = files.name;
          // 获取文件名，不包括文件后缀名
          let fName = showFileName.substr(0, index);
          if (fName.length > 30) {
            showFileName = fName.substring(0, 31) + "···." + ext;
          }
          let excelUrl = res.data;
          resolve({
            excelUrl,
            fileName,
            showFileName,
          });
        } else {
          resolve(false);
        }
      });
    } else {
      Message({
        message: "上传文件格式不正确",
        type: "warning",
      });
      resolve(false);
    }
  });
};

// word文件上传封装
export const wordFileUpload = (files) => {
  if (way.checkUrl(files.name)) {
    Message({
      message: "文件名内不能带有+、%",
      type: "warning",
    });
    return;
  }
  return new Promise((resolve, reject) => {
    //获取最后一个.的位置
    let index = files.name.lastIndexOf(".");
    //获取后缀
    let ext = files.name.substr(index + 1);
    if (ext == "doc" || ext == "docx") {
      request.uploadPic(files, (res) => {
        if (res.code == 0) {
          let fileName = files.name;
          let showFileName = files.name;
          // 获取文件名，不包括文件后缀名
          let fName = showFileName.substr(0, index);
          if (fName.length > 30) {
            showFileName = fName.substring(0, 31) + "···." + ext;
          }
          let wordUrl = res.data;
          resolve({
            wordUrl,
            fileName,
            showFileName,
          });
        } else {
          resolve(false);
        }
      });
    } else {
      Message({
        message: "上传文件格式不正确",
        type: "warning",
      });
      resolve(false);
    }
  });
};

// zip文件上传封装
export const zipFileUpload = (files) => {
  if (way.checkUrl(files.name)) {
    Message({
      message: "文件名内不能带有+、%",
      type: "warning",
    });
    return;
  }
  return new Promise((resolve, reject) => {
    //获取最后一个.的位置
    let index = files.name.lastIndexOf(".");
    //获取后缀
    let ext = files.name.substr(index + 1);
    if (ext == "zip" || ext == "rar") {
      request.uploadPic(files, (res) => {
        if (res.code == 0) {
          let fileName = files.name;
          let showFileName = files.name;
          // 获取文件名，不包括文件后缀名
          let fName = showFileName.substr(0, index);
          if (fName.length > 30) {
            showFileName = fName.substring(0, 31) + "···." + ext;
          }
          let excelUrl = res.data;
          resolve({
            excelUrl,
            fileName,
            showFileName,
          });
        } else {
          resolve(false);
        }
      });
    } else {
      Message({
        message: "上传文件格式不正确",
        type: "warning",
      });
      resolve(false);
    }
  });
};

// pdf文件上传封装
export const pdfFileUpload = (files) => {
  if (way.checkUrl(files.name)) {
    Message({
      message: "文件名内不能带有+、%",
      type: "warning",
    });
    return;
  }
  return new Promise((resolve, reject) => {
    //获取最后一个.的位置
    let index = files.name.lastIndexOf(".");
    //获取后缀
    let ext = files.name.substr(index + 1);
    if (ext == "pdf") {
      //uploadPic  uploadPrint
      request.uploadPrint(files, (res) => {
        if (res.code == 0) {
          let fileName = files.name;
          let showFileName = files.name;
          // 获取文件名，不包括文件后缀名
          let fName = showFileName.substr(0, index);
          if (fName.length > 30) {
            showFileName = fName.substring(0, 31) + "···." + ext;
          }
          let excelUrl = res.data;
          resolve({
            excelUrl,
            fileName,
            showFileName,
          });
        } else {
          resolve(false);
        }
      });
    } else {
      Message({
        message: "上传文件格式不正确",
        type: "warning",
      });
      resolve(false);
    }
  });
};
